import React from "react";
import { withTranslation } from "react-i18next";
import MiContent from "../components/Projects/Stand/MiContent";
import TecnoContent from "../components/Projects/Stand/TecnoContent";

const StandScreen = ({ t }) => {
    return (
        <>
            <section className="uk-section uk-section-small">
                <div className="uk-container">
                    <div className="uk-h1 uk-text-bold" data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">{t("projectTitle5")}</div>
                </div>
            </section>
            <MiContent/>
            <TecnoContent/>
        </>
    );
};

export default withTranslation()(StandScreen);