/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const AntalyaOzdilekMiStoreSlider = ({t}) => {
    return (
        <section className="uk-section uk-padding-remove-top">
            <div className="uk-container">
                <div className="uk-child-width-1-3@m" uk-lightbox="animation: slide" data-uk-grid data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300;">
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/0.jpg`} data-caption={`${t("projectTitle2")}-1`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/thumbnail/0.jpg`} alt={`${t("projectTitle2")}-1`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/1.jpg`} data-caption={`${t("projectTitle2")}-2`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/thumbnail/1.jpg`} alt={`${t("projectTitle2")}-2`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/2.jpg`} data-caption={`${t("projectTitle2")}-3`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/thumbnail/2.jpg`} alt={`${t("projectTitle2")}-3`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/3.jpg`} data-caption={`${t("projectTitle2")}-4`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/thumbnail/3.jpg`} alt={`${t("projectTitle2")}-4`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/4.jpg`} data-caption={`${t("projectTitle2")}-5`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/thumbnail/4.jpg`} alt={`${t("projectTitle2")}-5`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/5.jpg`} data-caption={`${t("projectTitle2")}-6`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/antalya-ozdilek/thumbnail/5.jpg`} alt={`${t("projectTitle2")}-6`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default withTranslation()(AntalyaOzdilekMiStoreSlider);