import React from "react";
import { withTranslation } from "react-i18next";
import TecnoOfisContent from "../components/Projects/TecnoOfis/TecnoOfisContent";
import TecnoOfisSlider from "../components/Projects/TecnoOfis/TecnoOfisSlider";

const TecnoOfisScreen = ({ t }) => {
    return (
        <>
            <TecnoOfisContent/>
            <TecnoOfisSlider/>
        </>
    );
};

export default withTranslation()(TecnoOfisScreen);