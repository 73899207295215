/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import image from "../../assets/partners/1.png";
import image2 from "../../assets/partners/2.png";
import image3 from "../../assets/partners/3.png";
import image4 from "../../assets/partners/4.png";
import image5 from "../../assets/partners/5.png";
import image6 from "../../assets/partners/6.png";
import image7 from "../../assets/partners/7.png";
import image8 from "../../assets/partners/8.png";

const PartnersSection = ({ t }) => {
    return (
        <div className="uk-section">
            <div className="uk-container">
            <div data-uk-slider="finite:true;">
                    <div className="uk-position-relative">
                        <div className="uk-slider-container" tabIndex="-1">
                            <ul className="uk-slider-items uk-child-width-1-2 uk-child-width-1-4@m uk-grid-match" data-uk-grid data-uk-scrollspy="target: > li; cls: uk-animation-fade; delay: 300;">
                                <li>
                                    <div className="uk-padding uk-flex uk-flex-center uk-flex-middle">
                                        <img src={image8} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-padding uk-flex uk-flex-center uk-flex-middle">
                                        <img src={image} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-padding uk-flex uk-flex-center uk-flex-middle">
                                        <img src={image2} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-padding uk-flex uk-flex-center uk-flex-middle">
                                        <img src={image3} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-padding uk-flex uk-flex-center uk-flex-middle">
                                        <img src={image4} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-padding uk-flex uk-flex-center uk-flex-middle">
                                        <img src={image5} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-padding uk-flex uk-flex-center uk-flex-middle">
                                        <img src={image6} alt="" />
                                    </div>
                                </li>
                                <li>
                                    <div className="uk-padding uk-flex uk-flex-center uk-flex-middle">
                                        <img src={image7} alt="" />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="uk-hidden@xl">
                            <a className="uk-position-center-left uk-position-small text-white" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                            <a className="uk-position-center-right uk-position-small text-white" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                        </div>
                        <div className="uk-visible@xl">
                            <a className="uk-position-center-left-out uk-position-small text-white" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                            <a className="uk-position-center-right-out uk-position-small text-white" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(PartnersSection);