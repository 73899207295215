import React from "react";
import { withTranslation } from "react-i18next";
import FirstServicesCard from "../components/Services/FirstServicesCard";
import FourthServicesCard from "../components/Services/FourthServicesCard";
import SecondServicesCard from "../components/Services/SecondServicesCard";
import ServicesBanner from "../components/Services/ServicesBanner";
import ThirdServicesCard from "../components/Services/ThirdServicesCard";

const ServicesScreen = ({ t }) => {
    return (
        <>
            <ServicesBanner/>
            <FirstServicesCard/>
            <SecondServicesCard/>
            <ThirdServicesCard/>
            <FourthServicesCard/>
        </>
    );
};

export default withTranslation()(ServicesScreen);