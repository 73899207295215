/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import projectsData from "../../data/projectsData";
import ProjectCard from "./Projects/ProjectCard";

const ProjectsSection = ({t}) => {
    return (
        <section className="uk-section">
            <div className="uk-container">
                <div className="uk-flex-middle" data-uk-grid data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">
                    <div className="uk-width-auto">
                        <div className="uk-h2 text-white uk-text-uppercase">{t("homeProjects")}</div>
                    </div>
                    <div className="uk-width-expand text-white uk-text-right">
                        <Link to="/projects" className="uk-link-reset">{t("showAll")}<span className="uk-margin-small-left" uk-icon="arrow-right"></span></Link>
                    </div>
                </div>
            </div>
            <div className="uk-section uk-padding-remove-bottom uk-overflow-hidden">
                <div className="uk-container uk-container-xlarge">
                    <div className="uk-position-relative" data-uk-slider="sets:true;finite:true;">
                        <div className="uk-slider-container">
                            <div>
                                <ul className="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m home-project-list" data-uk-grid data-uk-scrollspy="target: > li; cls: uk-animation-fade; delay: 300;">
                                    {projectsData.map((projectCard) => (
                                        <li>
                                            <ProjectCard key={projectCard._id} projectCard={projectCard}></ProjectCard>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="uk-hidden@xl">
                                <a className="uk-position-center-left uk-position-small text-white" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                <a className="uk-position-center-right uk-position-small text-white" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                            </div>
                            <div className="uk-visible@xl">
                                <a className="uk-position-center-left-out uk-position-small text-white" href="#" data-uk-slidenav-previous data-uk-slider-item="previous"></a>
                                <a className="uk-position-center-right-out uk-position-small text-white" href="#" data-uk-slidenav-next data-uk-slider-item="next"></a>
                            </div>
                        </div>
                        <ul className="uk-slider-nav uk-dotnav line-dotnav uk-flex-center uk-margin uk-visible@m"></ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ProjectsSection);