import React from "react";
import { withTranslation } from "react-i18next";
import PartnersSection from "../components/Home/PartnersSection";
import ImageAndText from "../components/WhoAreWe/ImageAndText";
import WhoAreWeBanner from "../components/WhoAreWe/WhoAreWeBanner";

const WhoWeAreScreen = ({ t }) => {
    return (
        <>
            <WhoAreWeBanner/>
            <ImageAndText/>
            <PartnersSection/>
        </>
    );
};

export default withTranslation()(WhoWeAreScreen);