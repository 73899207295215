/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//import dummy from "../../../assets/dummy.gif";

const ProjectCard = (props) => {
    const {projectCard , t} = props;
    return (
        <section className="uk-section" key={projectCard._id}>
            <div className="uk-container">
                <div className="uk-grid-collapse uk-grid-match" data-uk-grid data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">
                    <div className="uk-width-1-3@m">
                        <img src={`${process.env.PUBLIC_URL}/assets/projects/${projectCard.imageUrlTitle}/banner.png`} alt={t(`${projectCard.imageUrlTitle}`)} loading="lazy" className="uk-width-1-1 uk-height-1-1 uk-border-rounded" />
                    </div>
                    <div className="uk-width-2-3@m">
                        <div>
                            <div className="uk-padding uk-height-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                                <div className="uk-width-1-1">
                                    <div className="uk-h2 uk-text-emphasis uk-text-600 uk-margin-small-bottom">{t(`${projectCard.title}`)}</div>
                                    <p className="text-white">{t(`${projectCard.desc}`)}</p>
                                    <div className="text-white">
                                        <Link to={`/${projectCard.imageUrlTitle}`} className="uk-button uk-button-primary uk-border-pill">{t("showProject")}<span className="uk-margin-small-right" uk-icon="arrow-right"></span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default withTranslation()(ProjectCard);