const projectsData = [
    {
        id: 1,
        title: "projectTitle",
        desc: "projectDesc",
        imageUrlTitle: 'ankara-nata-vega',
        totalImage: 7,
    },
    {
        id: 2,
        title: "projectTitle2",
        desc: "projectDesc2",
        imageUrlTitle: 'antalya-ozdilek',
        totalImage: 6,
    },
    {
        id: 3,
        title: "projectTitle3",
        desc: "projectDesc3",
        imageUrlTitle: 'forum-mi',
        totalImage: 4,
    },
    {
        id: 4,
        title: "projectTitle4",
        desc: "projectDesc4",
        imageUrlTitle: 'tecno-ofis',
        totalImage: 6,
    },
    {
        id:5,
        title: "projectTitle5",
        desc: "projectDesc5",
        imageUrlTitle: 'stands',
        totalImage: 5,
    }
]

export default projectsData;