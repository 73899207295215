/* eslint-disable no-undef */
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';

const ContactForm = ({ t }) => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_0advj5m', 'template_zl7rpmr', form.current, 'AHiqrAyYkzNik9QiX')
        .then((result) => {
            console.log(result.text);
            UIkit.notification(`<span uk-icon='icon: check'></span> ${t("formSuccesMessage")}`,{status:'success'});
        }, (error) => {
            console.log(error.text);
            UIkit.notification(`<span uk-icon='icon: warning'></span> ${t("formDangerMessage")}`,{status:'danger'});
        });
        e.target.reset();
    };
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-grid-collapse uk-child-width-1-1 uk-background-secondary" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-center">
                        <div className="uk-width-1-2@m ">
                            <form className="uk-grid-small uk-flex-center uk-margin-auto uk-width-1-2@m uk-padding uk-padding-remove-horizontal uk-padding-remove-bottom" data-uk-grid ref={form} onSubmit={sendEmail}>
                                <div className="uk-width-1-1@s uk-padding-small uk-padding-remove-vertical">
                                    <label className="uk-form-label" htmlFor="name">{t("contactName")}*</label>
                                    <div className="uk-inline uk-width-1-1@s">
                                        <span className="uk-form-icon" uk-icon="icon: user"></span>
                                        <input className="uk-input" type="text" placeholder={t("namePlaceholder")} id="name" name="name" autoComplete="off" required/>
                                    </div>
                                </div>
                                <div className="uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label" htmlFor="email">{t("email")}*</label>
                                    <div className="uk-inline uk-width-1-1@s">
                                        <span className="uk-form-icon" uk-icon="icon: mail"></span>
                                        <input className="uk-input" type="email" placeholder={t("emailPlaceholder")} id="email" name="email" autoComplete="off" required/>
                                    </div>
                                </div>
                                <div className="uk-width-1-1@s uk-padding-small">
                                    <label className="uk-form-label" htmlFor="subject">{t("subject")}*</label>
                                    <div className="uk-inline uk-width-1-1@s">
                                        <span className="uk-form-icon" uk-icon="icon: quote-right"></span>
                                        <input className="uk-input" type="text" placeholder={t("subjectPlaceholder")} id="subject" name="subject" autoComplete="off" required/>
                                    </div>
                                </div>
                                <div className="uk-width-1-1@s uk-padding-small uk-padding-remove-vertical">
                                    <label className="uk-form-label" htmlFor="message">{t("message")}*</label>
                                    <textarea className="uk-textarea resize-none" rows="5" placeholder={t("messagePlaceholder")} id="message" name="message" autoComplete="off" required></textarea>
                                </div>
                                <div className="uk-width-1-1@s uk-flex-center uk-flex uk-padding">
                                    <button className="uk-button uk-button-primary uk-border-rounded" type="submit">{t("submit")}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ContactForm);