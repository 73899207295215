import React from "react";
import { withTranslation } from "react-i18next";
import AnkaraNataContent from "../components/Projects/AnkaraNata/AnkaraNataContent";
import AnkaraNataSlider from "../components/Projects/AnkaraNata/AnkaraNataSlider";

const AnkaraNataScreen = ({ t }) => {
    return (
        <>
            <AnkaraNataContent/>
            <AnkaraNataSlider/>
        </>
    );
};

export default withTranslation()(AnkaraNataScreen);