import React from "react";
import { withTranslation } from "react-i18next";
import AntalyaOzdilekMiStoreContent from "../components/Projects/AntalyaOzdilekMiStore/AntalyaOzdilekMiStoreContent";
import AntalyaOzdilekMiStoreSlider from "../components/Projects/AntalyaOzdilekMiStore/AntalyaOzdilekMiStoreSlider";

const AntalyaOzdilekMiStoreScreen = ({ t }) => {
    return (
        <>
            <AntalyaOzdilekMiStoreContent/>
            <AntalyaOzdilekMiStoreSlider/>
        </>
    );
};

export default withTranslation()(AntalyaOzdilekMiStoreScreen);