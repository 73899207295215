import React from "react";
import { withTranslation } from "react-i18next";
import banner from "../../assets/banner/services-banner.jpg";

const ServicesBanner = ({ t }) => {
    return (
        <section>
            <div className="uk-inline uk-width-1-1">
                <img src={banner} alt="Mission Services Banner" class="uk-width-1-1 uk-position-relative uk-object-cover" loading="lazy"/>
                <div className="uk-position-cover uk-height-1-1 uk-flex uk-flex-middle">
                    <div className="uk-flex-1 uk-width-1-1">
                        <div className="uk-container">
                            <div className="uk-text-center">
                                <h1 className="text-white">{t("services")}</h1>
                                <hr className="uk-divider-small uk-margin-small-top uk-margin-small-bottom"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ServicesBanner);