import { Routes, Route, useLocation } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import MobileMenu from "./components/MobileMenu";
import { useEffect } from "react";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import WhoWeAreScreen from "./screens/WhoWeAreScreen";
import ContactScreen from "./screens/ContactScreen";
import ProjectsScreen from "./screens/ProjectsScreen";
import ServicesScreen from "./screens/ServicesScreen";
import AnkaraNataScreen from "./screens/AnkaraNataScreen";
import AntalyaOzdilekMiStoreScreen from "./screens/AntalyaOzdilekMiStoreScreen";
import ForumMiScreen from "./screens/ForumMiScreen";
import TecnoOfisScreen from "./screens/TecnoOfisScreen";
import StandScreen from "./screens/StandScreen";

function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }
  return (
    <>
      <Navbar/>
      <MobileMenu/>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<HomeScreen/>}/>
        <Route path="/our-about" element={<WhoWeAreScreen/>}/>
        <Route path="/contact" element={<ContactScreen/>}/>
        <Route path="/projects" element={<ProjectsScreen/>}/>
        <Route path="/services" element={<ServicesScreen/>}/>
        <Route path="/ankara-nata-vega" element={<AnkaraNataScreen/>}/>
        <Route path="/antalya-ozdilek" element={<AntalyaOzdilekMiStoreScreen/>}/>
        <Route path="/forum-mi" element={<ForumMiScreen/>}/>
        <Route path="/tecno-ofis" element={<TecnoOfisScreen/>}/>
        <Route path="/stands" element={<StandScreen/>}/>
      </Routes>
      <Footer/>
    </>
  );
}

export default App;