import React from "react";
import { withTranslation } from "react-i18next";
import DesktopPromoSlider from "./PromoSlider/DesktopPromoSlider";
import MobilePromoSlider from "./PromoSlider/MobilePromoSlider";

const PromoSlider = ({ t }) => {
    return (
        <>
            <DesktopPromoSlider/>
            <MobilePromoSlider/>
        </>
    );
};

export default withTranslation()(PromoSlider);