/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { withTranslation } from "react-i18next";
import React from 'react';
import { Link } from 'react-router-dom';
import phonyloopsLogo from "../assets/phonyloops-logo.png";
import logo from "../assets/footer-logo.png";

const Footer = ({ t }) => {
    return (
        <footer>
            <section className="uk-section uk-background-secondary">
                <div className="uk-container">
                    <div className="uk-child-width-1-1 uk-child-width-1-3@m" data-uk-grid data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300;">
                        <div className='uk-visible@m'>
                            <div className="uk-flex uk-flex-column uk-flex-center">
                                <div className="logo">
                                    <Link to="/"><img src={logo} alt="" className='footer-logo'></img></Link>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className="uk-h3 uk-margin-remove uk-text-bold text-white">{t("menu")}</div>
                            <div className="line"/>
                            <ul className="uk-list text-white link">
                                <li><Link to="/">{t("home")}</Link></li>
                                <li><Link to="/our-about">{t("whoAreWe")}</Link></li>
                                <li><Link to="/projects">{t("projects")}</Link></li>
                                <li><Link to="/services">{t("services")}</Link></li>
                                <li><Link to="/contact">{t("contact")}</Link></li>
                            </ul>
                        </div>
                        <div>
                            <div className="uk-flex uk-flex-column uk-flex-left">
                                <div className="uk-h3 uk-margin-remove uk-text-bold text-white">{t("locationInformation")}</div>
                                <div className="line"/>
                                <div className="text-white">
                                    <div>
                                        YEŞİLCE MAH. YILDIRIM SK. NO: 7 İÇ KAPI NO: 3 <br/> KAĞITHANE/ İSTANBUL
                                    </div>
                                    <div className="uk-padding-small uk-padding-remove-horizontal link">
                                        <a href="tel:+905351042525">+90 535 104 25 25</a> <br/> <a href="mailto:info@missiondanismanlik.com" className="uk-link-reset">info@missiondanismanlik.com</a>
                                    </div>
                                    <div className="uk-padding-small uk-padding-remove-horizontal link">
                                        <a href="https://goo.gl/maps/oVp1QsyLGLBwx2oF7" target="_blank">{t("showOnMap")}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="uk-section uk-section-xsmall uk-background-secondary footer-divider" data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300;">
                {/*Mobile*/}
                <div className="uk-container uk-width-1-1 uk-margin-auto uk-text-center uk-grid-collapse uk-hidden@m" data-uk-grid>
                    <div className="uk-width-1-1 uk-text-small">
                        <div className="text-white">{t("copyrightText")}</div>
                    </div>
                    <div className="uk-width-1-1 uk-margin">
                        <a href="http://www.phonyloops.com"><img src={phonyloopsLogo} alt="phonyloops-logo"/></a>
                    </div>
                </div>
                {/*Desktop*/}
                <div className="uk-container uk-width-1-1 uk-margin-auto uk-grid-collapse uk-flex-middle uk-visible@m" data-uk-grid data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300;">
                    <div className="uk-width-expand@m uk-flex uk-flex-column uk-flex-left uk-padding uk-padding-remove-vertical uk-padding-remove-right uk-text-small">
                        <div className="text-white">{t("copyrightText")}</div>
                    </div>
                    <div className="uk-width-auto@m uk-flex uk-flex-right uk-padding uk-padding-remove-vertical uk-padding-remove-left">
                        <a href="http://www.phonyloops.com"><img src={phonyloopsLogo} alt="phonyloops-logo"/></a>
                    </div>
                </div>
            </section>
        </footer>
    );
};

export default withTranslation()(Footer);