import React from "react";
import { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UIkit from "uikit";

const MobileMenu = ({ t }) => {
    useEffect(() => {
        const offcanvasList = document.getElementById("offcanvasList").querySelectorAll("li");
        offcanvasList.forEach((item) => {
            item.addEventListener("click", function(){
                UIkit.offcanvas('#mobile-menu').hide(0);
            });
        })
    });
    return (
        <div id="mobile-menu" data-uk-offcanvas="overlay: true; flip:true; esc-close:false;">
            <button className="uk-offcanvas-close uk-text-large icon" type="button" data-uk-close></button>
            <div className="uk-offcanvas-bar uk-flex uk-flex-column">
                <ul className="uk-nav uk-nav-default uk-nav-center uk-margin-auto-vertical uk-text-large" id="offcanvasList">
                    <li><Link to="/">{t("home")}</Link></li>
                    <li><Link to="/our-about">{t("whoAreWe")}</Link></li>
                    <li><Link to="/projects">{t("projects")}</Link></li>
                    <li><Link to="/services">{t("services")}</Link></li>
                    <li><Link to="/contact">{t("contact")}</Link></li>
                </ul>
                <div className="uk-flex uk-flex-center uk-padding">
                    <Link to="/" uk-icon="icon:icn-header-logo; ratio:0.02;"></Link>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(MobileMenu);