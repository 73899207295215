/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { withTranslation } from "react-i18next";

const ContactMap = ({ t }) => {
    const iframeSrc = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d187.921411655294!2d29.001343107340237!3d41.09647060000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab67e4e8d9b3b%3A0xefa37a40d4ef95cd!2zWWXFn2lsY2UsIFnEsWxkxLFyxLFtIFNrLiBObzo3LCAzNDQxOCBLw6LEn8SxdGhhbmUvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1662921762554!5m2!1str!2str';
    return (
        <section className="uk-section uk-padding-remove-bottom">
            <div className="uk-container uk-container-xlarge">
                <div className="uk-margin-auto uk-grid-collapse uk-child-width-1-1" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                    <div className="grid-border uk-flex uk-flex-column uk-flex-center">
                        <div className="uk-width-1-1 uk-height-large">
                            <iframe
                            src={iframeSrc}
                            frameborder="0"
                            style={{ border: 0 }}
                            className="uk-width-1-1 uk-height-1-1"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ContactMap);