import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import image from "../../assets/services/services-2.png";

const SecondServicesCard = ({ t }) => {
    return (
        <section className="uk-section uk-background-primary">
            <div className="uk-container">
                <div className="uk-grid-collapse uk-grid-match mobile-row-reverse" data-uk-grid data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">
                    <div className="uk-width-2-3@m uk-flex-last uk-flex-first@m">
                        <div>
                            <div className="uk-padding uk-height-1-1 uk-flex uk-flex-column uk-flex-center uk-flex-middle">
                                <div className="uk-h2 uk-text-emphasis uk-width-1-1">{t("servicesCardTitle2")}</div>
                                <div className="text-white uk-margin-small-bottom">{t("servicesCardDesc2")}</div>
                                <div className="text-white uk-margin-small-bottom">{t("servicesCardDesc2_1")}</div>
                                <div className="text-white">{t("servicesCardDesc2_2")}</div>
                                <div className="uk-width-1-1 uk-flex uk-flex-left uk-margin-top">
                                    <Link to="/contact" className="uk-button uk-border-pill uk-button-primary">{t("contactUs")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="uk-width-1-3@m uk-flex-first uk-flex-last@m">
                        <div>
                            <img src={image} alt="" loading="lazy" className="uk-width-1-1 uk-height-1-1 uk-border-rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(SecondServicesCard);