/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//import dummy from "../../../assets/dummy.gif";

const ProjectCard = (props) => {
    const {projectCard , t} = props;
    return (
        <div className="uk-link-reset uk-height-1-1 project-card" key={projectCard._id}>
            <div className="uk-inline-clip uk-transition-toggle uk-width-1-1" tabIndex="0">
                <img src={`${process.env.PUBLIC_URL}/assets/projects/${projectCard.imageUrlTitle}/banner.png`} alt={t(`${projectCard.imageUrlTitle}`)} loading="lazy" className="uk-width-1-1"/>
                <div className="uk-position-bottom uk-position-medium uk-width-1-1">
                    <div className="uk-h2 text-white uk-margin-small-bottom">{t(`${projectCard.title}`)}</div>
                    <div className="text-white">
                        <div className="uk-link-reset">{t("showProject")}<span className="uk-margin-small-right" uk-icon="arrow-right"></span></div>
                    </div>
                </div>
                <div className="uk-transition-fade uk-position-cover uk-overlay uk-overlay-default uk-flex uk-flex-center uk-flex-middle">
                    <div className="uk-padding uk-padding-small uk-height-1-1 uk-width-1-1 uk-flex uk-flex-column uk-flex-between">
                        <div></div>
                        <div>
                            <div className="uk-h2 text-white">{t(`${projectCard.title}`)}</div>
                        </div>
                        <div>
                            <div className="text-white">
                                <Link to={`/${projectCard.imageUrlTitle}`} className="uk-link-reset">{t("showProject")}<span className="uk-margin-small-right" uk-icon="arrow-right"></span></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        // <div className=" project-card">
        //     <a className="uk-link-reset uk-height-1-1" key={projectCard._id} data-id={projectCard.id}>
        //         <div className="uk-inline-clip uk-transition-toggle uk-width-1-1" tabIndex="0">
        //             <img src={`${process.env.PUBLIC_URL}/assets/projects/${projectCard.imageUrlTitle}/banner.png`} alt={t(`${projectCard.imageUrlTitle}`)} loading="lazy" className="uk-width-1-1"/>
        //             <div className="uk-position-bottom uk-position-medium uk-width-1-1">
        //                 <div className="uk-h3 text-white uk-margin-small-bottom">{t(`${projectCard.title}`)}</div>
        //                 <div className="text-white">
        //                     <div className="uk-link-reset">{t("showProject")}<span className="uk-margin-small-right" uk-icon="arrow-right"></span></div>
        //                 </div>
        //             </div>
        //             <img className="uk-transition-scale-up uk-position-cover" src={dummy} alt="" loading="lazy" style={{width:'400px', height:'600px'}} />
        //         </div>
        //     </a>
        // </div>
    );
};
export default withTranslation()(ProjectCard);