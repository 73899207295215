import React from "react";
import { withTranslation } from "react-i18next";
import homeAbout from "../../assets/home-about.png";

const ImageAndText = ({ t }) => {
    return (
    <section className="uk-section">
        <div className="uk-container">
            <div className="uk-grid-collapse uk-grid-match" data-uk-grid>
                <div className="uk-width-1-2 uk-visible@m">
                    <div className="uk-background-secondary">
                        <div className="uk-padding uk-height-1-1 uk-flex uk-flex-column uk-flex-center" data-uk-scrollspy="target: > div; cls: uk-animation-slide-left-small; delay: 300;">
                            <div className="uk-h2 uk-text-emphasis uk-text-bold">{t("whoWeAreHeader")}</div>
                            <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc")}</div>
                            <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc2")}</div>
                            <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc3")}</div>
                            <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc4")}</div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-2 uk-visible@m">
                    <div className="uk-background-secondary uk-padding" data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">
                        <img src={homeAbout} alt="" loading="lazy" className="uk-width-1-1 uk-height-1-1 uk-border-rounded"/>
                    </div>
                </div>
                <div className="uk-width-1-1 uk-hidden@m">
                    <div className="uk-background-secondary">
                        <div className="uk-padding uk-height-1-1 uk-flex uk-flex-column uk-flex-center" data-uk-scrollspy="target: > div; cls: uk-animation-slide-left-small; delay: 300;">
                            <div className="uk-h2 uk-text-emphasis uk-text-bold">{t("whoWeAreHeader")}</div>
                            <div data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">
                                <img src={homeAbout} alt="" loading="lazy" className="uk-width-1-1 uk-height-1-1 uk-border-rounded"/>
                            </div>
                            <div className="text-white uk-margin-small-bottom uk-margin-top">{t("whoWeAreDesc")}</div>
                            <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc2")}</div>
                            <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc3")}</div>
                            <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc4")}</div>
                        </div>
                    </div>
                </div>
                <div className="uk-width-1-1 uk-background-secondary">
                    <div className="uk-padding uk-padding-remove-top">
                        <div className="uk-h4 uk-text-emphasis uk-text-bold uk-margin-top">{t("whoWeAreTitle")}</div>
                        <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc5")}</div>
                        <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc6")}</div>
                        <div className="text-white uk-margin-small-bottom">{t("whoWeAreDesc7")}</div>
                        <div className="text-white">{t("whoWeAreDesc8")}</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
};

export default withTranslation()(ImageAndText);