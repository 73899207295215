/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withTranslation } from "react-i18next";
import projectsData from "../../data/projectsData";
import ProjectCard from "../Projects/ProjectCard";

const AllProjects = ({ t }) => {
    return (
        <div className="section-projects">
            {projectsData.map((projectCard) => (
            <ProjectCard key={projectCard._id} projectCard={projectCard}></ProjectCard>
            ))}
        </div>
    );
};

export default withTranslation()(AllProjects);