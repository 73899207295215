import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import slider1 from "../../../assets/homeSlider/slider-1.jpg";
import slider2 from "../../../assets/homeSlider/slider-2.jpg";
import slider3 from "../../../assets/homeSlider/slider-3.jpg";
import slider4 from "../../../assets/homeSlider/slider-4.jpg";
import slider5 from "../../../assets/homeSlider/slider-5.jpg";

const DesktopPromoSlider = ({ t }) => {
    return (
        <div className="uk-visible@m" data-uk-slideshow>
            <div className="uk-position-relative uk-visible-toggle" tabIndex="-1">
                <ul className="uk-slideshow-items home-promo-slider" data-uk-height-viewport="offset-top:true;">
                    <li>
                        <div className="uk-grid-collapse uk-flex-middle uk-grid-match uk-height-1-1" data-uk-grid>
                            <div className="uk-width-1-2 uk-background-secondary grid-border uk-height-1-1 uk-flex uk-flex-middle uk-flex-center">
                                <div className="uk-card  animation-border uk-padding-remove-right uk-flex uk-flex-column uk-flex-between promo-slider-card" data-uk-scrollspy="target: > div; cls: uk-animation-slide-right; delay: 600;repeat:true;">
                                    <div className="uk-position-relative title">{t("projectTitle")}</div>
                                    <div className="text-white"><Link to="/ankara-nata-vega" className="uk-link-reset">{t("showProject")}</Link></div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 grid-border uk-height-1-1">
                                <img src={slider1} alt="" className="uk-width-1-1 uk-object-cover"/>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="uk-grid-collapse uk-flex-middle uk-grid-match uk-height-1-1" data-uk-grid>
                            <div className="uk-width-1-2 uk-background-secondary grid-border uk-height-1-1 uk-flex uk-flex-middle uk-flex-center">
                                <div className="uk-card  animation-border uk-padding-remove-right uk-flex uk-flex-column uk-flex-between promo-slider-card" data-uk-scrollspy="target: > div; cls: uk-animation-slide-right; delay: 600;repeat:true;">
                                    <div className="uk-position-relative title">{t("projectTitle2")}</div>
                                    <div className="text-white"><Link to="/antalya-ozdilek" className="uk-link-reset">{t("showProject")}</Link></div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 grid-border uk-height-1-1">
                                <img src={slider2} alt="" className="uk-width-1-1 uk-object-cover"/>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="uk-grid-collapse uk-flex-middle uk-grid-match uk-height-1-1" data-uk-grid>
                            <div className="uk-width-1-2 uk-background-secondary grid-border uk-height-1-1 uk-flex uk-flex-middle uk-flex-center">
                                <div className="uk-card  animation-border uk-padding-remove-right uk-flex uk-flex-column uk-flex-between promo-slider-card" data-uk-scrollspy="target: > div; cls: uk-animation-slide-right; delay: 600;repeat:true;">
                                    <div className="uk-position-relative title">{t("projectTitle3")}</div>
                                    <div className="text-white"><Link to="/forum-mi" className="uk-link-reset">{t("showProject")}</Link></div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 grid-border uk-height-1-1">
                                <img src={slider3} alt="" className="uk-width-1-1 uk-object-cover"/>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="uk-grid-collapse uk-flex-middle uk-grid-match uk-height-1-1" data-uk-grid>
                            <div className="uk-width-1-2 uk-background-secondary grid-border uk-height-1-1 uk-flex uk-flex-middle uk-flex-center">
                                <div className="uk-card  animation-border uk-padding-remove-right uk-flex uk-flex-column uk-flex-between promo-slider-card" data-uk-scrollspy="target: > div; cls: uk-animation-slide-right; delay: 600;repeat:true;">
                                    <div className="uk-position-relative title">{t("projectTitle4")}</div>
                                    <div className="text-white"><Link to="/tecno-ofis" className="uk-link-reset">{t("showProject")}</Link></div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 grid-border uk-height-1-1">
                                <img src={slider4} alt="" className="uk-width-1-1 uk-object-cover"/>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="uk-grid-collapse uk-flex-middle uk-grid-match uk-height-1-1" data-uk-grid>
                            <div className="uk-width-1-2 uk-background-secondary grid-border uk-height-1-1 uk-flex uk-flex-middle uk-flex-center">
                                <div className="uk-card  animation-border uk-padding-remove-right uk-flex uk-flex-column uk-flex-between promo-slider-card" data-uk-scrollspy="target: > div; cls: uk-animation-slide-right; delay: 600;repeat:true;">
                                    <div className="uk-position-relative title">{t("projectTitle5")}</div>
                                    <div className="text-white"><Link to="/stands" className="uk-link-reset">{t("showProject")}</Link></div>
                                </div>
                            </div>
                            <div className="uk-width-1-2 grid-border uk-height-1-1">
                                <img src={slider5} alt="" className="uk-width-1-1 uk-object-cover"/>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <ul className="uk-slideshow-nav line-dotnav uk-dotnav uk-position-bottom-center uk-margin"></ul>
        </div>
    );
};

export default withTranslation()(DesktopPromoSlider);