/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import i18next from "i18next";

const Navbar = ({ t }) => {
    const onClick = (lang) => {
        i18next.changeLanguage(lang);
        const currentLang = document.getElementById("currentLang");
        const tr = document.getElementById("tr");
        const en = document.getElementById("en");
        tr.removeAttribute("class");
        en.removeAttribute("class");
        if(lang === "tr")
        {
            currentLang.innerHTML = "TR";
            tr.classList.add("uk-active");
        } else {
            currentLang.innerHTML = "EN";
            en.classList.add("uk-active");
        }
    };
    return (
        <header className="navbar-bg" data-uk-sticky>
            <nav className="uk-container uk-container-xlarge uk-navbar-sticky uk-position-relative" data-uk-navbar data-uk-scrollspy="cls: uk-animation-fade; delay:300;">
                <div className="uk-navbar-left">
                    <div className="uk-navbar-item uk-padding-remove-left"><Link to="/" uk-icon="icon:icn-header-logo; ratio:0.017;"></Link></div>
                </div>
                <div className="uk-navbar-right uk-visible@m">
                    <div className="uk-navbar-item link">
                        <Link to="/" className="uk-link-reset">{t("home")}</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/our-about" className="uk-link-reset">{t("whoAreWe")}</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/projects" className="uk-link-reset">{t("projects")}</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/services" className="uk-link-reset">{t("services")}</Link>
                    </div>
                    <div className="uk-navbar-item link">
                        <Link to="/contact" className="uk-link-reset">{t("contact")}</Link>
                    </div>
                    <div className="uk-navbar-item uk-padding-remove-right">
                        <a href="#" className="uk-link-reset" id="currentLang">TR</a>
                        <div className="uk-navbar-dropdown" uk-drop="pos: bottom-center;offset:25px;">
                            <ul className="uk-nav uk-navbar-dropdown-nav">
                                <li className="uk-active" onClick={() => onClick("tr")} type="button" id="tr"><a href="#">TR</a></li>
                                <li type="button" onClick={() => onClick("en")} id="en"><a href="#">EN</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="uk-navbar-right uk-hidden@m uk-padding-remove-right">
                    <div className="uk-navbar-item link">
                        <a className="uk-link-reset uk-text-large" data-uk-icon="menu" data-uk-toggle="target: #mobile-menu"></a>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default withTranslation()(Navbar);