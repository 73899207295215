/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const TecnoOfisSlider = ({t}) => {
    return (
        <section className="uk-section uk-padding-remove-top">
            <div className="uk-container">
                <div className="uk-child-width-1-2@s uk-child-width-1-3@m" uk-lightbox="animation: slide" data-uk-grid data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300;">
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/0.jpg`} data-caption={`${t("projectTitle4")}-1`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/thumbnail/0.jpg`} alt={`${t("projectTitle4")}-1`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/1.jpg`} data-caption={`${t("projectTitle4")}-2`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/thumbnail/1.jpg`} alt={`${t("projectTitle4")}-2`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/2.jpg`} data-caption={`${t("projectTitle4")}-3`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/thumbnail/2.jpg`} alt={`${t("projectTitle4")}-3`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/3.jpg`} data-caption={`${t("projectTitle4")}-4`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/thumbnail/3.jpg`} alt={`${t("projectTitle4")}-4`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/4.jpg`} data-caption={`${t("projectTitle4")}-5`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/thumbnail/4.jpg`} alt={`${t("projectTitle4")}-5`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/5.jpg`} data-caption={`${t("projectTitle4")}-6`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/tecno-ofis/thumbnail/5.jpg`} alt={`${t("projectTitle4")}-6`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default withTranslation()(TecnoOfisSlider);