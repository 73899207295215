/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { withTranslation } from "react-i18next";

const ContactDescripton = ({ t }) => {
    return (
        <section className="uk-section uk-padding-remove-bottom uk-padding-remove-top">
            <div className="uk-container uk-container-xlarge">
                <section className="uk-section">
                    <div className="uk-container uk-container-xlarge">
                        <div className="uk-grid-collapse uk-width-1-1 uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center uk-text-center uk-background-secondary" data-uk-grid data-uk-scrollspy="cls:uk-animation-fade; delay:300;">
                            <div className="grid-border uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div className="uk-text-white">
                                    <div uk-icon="icon: location; ratio: 2"></div>
                                </div>
                                <div className="uk-h4 uk-text-white uk-margin-small uk-text-bold">{t("address")}</div>
                                <div className="uk-h5 uk-text-white uk-margin-small link">
                                    <a href="https://goo.gl/maps/oVp1QsyLGLBwx2oF7" target="_blank">YEŞİLCE MAH. YILDIRIM SK. NO: 7 İÇ KAPI NO: 3 <br/> KAĞITHANE/ İSTANBUL</a>
                                </div>
                            </div>
                            <div className="grid-border uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div className="uk-text-white">
                                    <div uk-icon="icon: mail; ratio: 2"></div>
                                </div>
                                <div className="uk-h4 uk-text-white uk-margin-small uk-text-bold">{t("email")}</div>
                                <div className="uk-h5 uk-text-white uk-margin-small link"><a className="uk-link-reset" href="mailto:info@missiondanismanlik.com">info@missiondanismanlik.com</a></div>
                            </div>
                            <div className="grid-border uk-flex uk-flex-center uk-flex-column uk-padding">
                                <div className="uk-text-white">
                                    <div uk-icon="icon: phone; ratio: 2"></div>
                                </div>
                                <div className="uk-h4 uk-text-white uk-margin-small uk-text-bold">{t("telephone")}</div>
                                <div className="uk-h5 uk-text-white uk-margin-small link"><a href="tel:+905351042525" className="uk-link-reset">+90 535 104 25 25</a></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
    );
};

export default withTranslation()(ContactDescripton);