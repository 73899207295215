const translations = {
  en: {
    translation: {
      home:"Home",
      whoAreWe:"Who Are We?",
      projects:"Projects",
      services:"Services",
      contact:"Contact",
      locationInformation:"Location Information",
      menu:"Menu",
      copyrightText:`Copyright 2022 © All rights reserved`,
      showOnMap:"Show on Map",
      showMore:'Show More',
      showAll: 'Show All',
      homeProjects:"PROJECTS",
      showProject:"Show Project",
      projectTitle:"Ankara Nata Vega Mi Store",
      projectDesc:`It is Turkey's second new concept store, the construction of which was completed in May. The 125 square meter store took 25 days to build. Ankara/Mamak`,
      projectTitle2:"Antalya Özdilek Mi Store",
      projectDesc2:`The store, the construction of which was completed in March, is Turkey's first new concept store. The construction of the 130 square meter store took 1 month. Antalya / Kepez`,
      projectTitle3:"Forum İstanbul Mi Store",
      projectDesc3:"Forum Istanbul Mi Store, which is one of the first stores to be opened, has been half-opened to be the first new concept store in Istanbul. Istanbul/Bayrampasa",
      projectTitle4:"Tecno Fabrika",
      projectDesc4:"The 1200 square meter factory, which was established to produce the mobile phones of the Tecno brand, has the capacity to produce 1400 phones per month. Istanbul/Kurtkoy",
      projectTitle5:"Stands",
      projectDesc5:"We design the stands demanded by the brands we partner with, and provide the necessary support during the production and installation stages.",
      miStands:'Mi Store Stands',
      tecnoStands:'Tecno Stands',
      partners:"WORK PARTNERS",
      otherServices:"Other Services",
      whoWeAreHeader:"Who Are We?",
      whoWeAreTitle:"How do we work?",
      whoWeAreDesc:`Mission, with the power of its expert staff in the field of architecture and interior architecture, offers design and architectural services all over Turkey, especially in Istanbul. Aiming to provide the best possible service and in this direction, taking into account the wishes of its customers, it has adopted the principle of making quality architecture.`,
      whoWeAreDesc2:`Mission brings a new perspective by interpreting different examples by following the ever-changing architectural trends in the world with its disciplined staff and designs that make a difference in your life. With these methods, it aims to provide harmony in the space by adding detail to the understanding of architectural design and to integrate the living space with its users.`,
      whoWeAreDesc3:`Mission is a customer satisfaction oriented company that can meet the expectations in the field of architectural and interior design production and application by providing functional and transformable solutions for the spatial needs and tastes of its customers. Our firm draws the strength of its architectural experience from its dynamic and sharing principle, with its staff combining original designs that do not break with the architectural discipline with a meticulous application.`,
      whoWeAreDesc4:`Mission has adopted the principle of always presenting functional and functional designs that follow innovative modern architectural designs combined with today's design principles and aim to implement them in their projects with all their details.`,
      whoWeAreDesc5:`Specializing mainly in working and living spaces, Mission operates in a wide scope in the interior architecture and design sector, constantly expanding its sphere of influence with the projects it implements with an innovative and modern perspective.`,
      whoWeAreDesc6:`Thanks to the materials and technologies used in the project implementation processes, our company eliminates existing or potential problems by creating correct and permanent solutions. It responds to the problems it encounters in this process with the professional associations it creates.`,
      whoWeAreDesc7:`Believing that a qualified and inspiring project is possible thanks to an interactive dialogue with the employer as well as the continuity of the process, Mission adopts this approach as the most important tool to provide the user with a quality and happiness-filled space experience.`,
      whoWeAreDesc8:`Mission, which has been providing architectural and consultancy services to world-renowned phone manufacturers such as Tecno and Xiaomi since its establishment, transforms many stages of the design action, from concept design to project management, and brings its users together with the places they dream of.`,
      address:"Address",
      email:"Email Address",
      telephone:"Telephone",
      contactName:"Name Surname",
      subject:"Subject",
      message:"Message",
      submit:"Submit",
      namePlaceholder:"Enter name and surname",
      emailPlaceholder:"Enter email address",
      subjectPlaceholder:"Enter subject",
      messagePlaceholder:"Enter message",
      formSuccesMessage:"Your message successfully sent.",
      formDangerMessage:"your message could not be sent. Try Again!",
      servicesCardTitle:`Interior Architecture Design`,
      servicesCardDesc:`After analyzing your needs in detail, we offer a comfortable living space with our aesthetic and useful interior designs. Paying attention to the efficient and stylish use of spaces, Mission Consulting offers you eye-catching designs by combining customer expectations and corporate professionalism.`,
      servicesCardDesc_1:`Mission, which aims to achieve results with its planning, aesthetic and technical solutions, makes your interior useful, comfortable and stylish.`,
      servicesCardDesc_2:`Providing full equipment with aesthetic elements while decorating your personal tastes is one of the quality services provided by Mission Consulting.`,
      servicesCardDesc_3:`Mission adds a new identity to your living space with its expert architect staff.`,
      servicesCardTitle2:`Architectural Design`,
      servicesCardDesc2:`The stages of the request, preparation, design, zoning status and the detailed examination of the project, where the other experts who work with the architect and the other experts who work with him, plan the designs they have made, and the project for which the order is received, are considered as architectural implementation projects.`,
      servicesCardDesc2_1:`The architect who implements these practices works with experts in their field. The idea project, called the preliminary project, is presented with the concretization of each requested architectural work.`,
      servicesCardDesc2_2:`The existence of these implementation projects is the most important factor in getting out of the monotony of the plans that will be made concrete by Mission Consulting.`,
      servicesCardTitle3:`Consulting`,
      servicesCardDesc3:`Mission Consulting provides services to meet customer expectations with fast, practical, creative, rational, economical and technical solutions by using its equipment and sector experience.`,
      servicesCardDesc3_1:`Working with a focus on customer satisfaction throughout the process, Mission fulfills its architectural consultancy duty by managing all necessary stages.`,
      servicesCardDesc3_2:`For its customers, it defines and researches the problems related to the functionality and quality of the space, meets the expectations and at the same time produces optimum solutions.`,
      servicesCardTitle4:`Custom Furniture Design`,
      servicesCardDesc4:`With the touch of our expert architects, Mission Consulting provides solutions for your expectations and needs, with the color, design and size features decided in accordance with your demands.`,
      servicesCardDesc4_1:`All kinds of furniture that may be required for a place are designed specifically for the concept of the desired space.`,
      servicesCardDesc4_2:`Your specially designed furniture, produced in the desired color and texture, helps you create a great comfort area with its functionality as well as being remarkable.`,
      servicesCardDesc4_3:`Mission Consulting designs comfortable, harmonious and harmonious furniture suitable for your aesthetic taste for you.`,
      contactUs:'Contact Us',
    }
  },
  tr: {
    translation: {
      home:"Anasayfa",
      whoAreWe:"Biz Kimiz?",
      projects:"Projeler",
      services:"Hizmetler",
      contact:"İletişim",
      locationInformation:"Adres Bilgileri",
      menu:"Menü",
      copyrightText:`Copyright 2022 © Bütün hakları saklıdır.`,
      showOnMap:"Haritada Göster",
      showMore:'Devamını Göster',
      showAll: 'Tümünü Gör',
      homeProjects:"PROJELERİMİZ",
      showProject:"Projeyi Göster",
      projectTitle:"Ankara Nata Vega Mi Store",
      projectDesc:"Yapımı mayıs ayında tamamlanmış türkiyenin 2. yeni konsept mi mağazasıdır. 125 metrekarelik mağazanın yapımı 25 gün sürmüştür. Ankara/Mamak",
      projectTitle2:"Antalya Özdilek Mi Store",
      projectDesc2:`Yapımı mart ayında tamamlanmış mağaza türkiye'nin ilk yeni konsept mi mağazasıdır. 130 metrekarelik mağazanın yapımı 1 ay sürmüştür. Antalya/Kepez`,
      projectTitle3:"Forum İstanbul Mi Store",
      projectDesc3:`Açılan ilk mi mağazalarımızdan olan Forum İstanbul Mi Store İstanbulda ilk yeni konsept mağaza olmak için yarı açık bir şekilde tadilatına başlanıştır. İstanbul/Bayrampaşa`,
      projectTitle4:"Tecno Fabrika",
      projectDesc4:`Tecno markasının cep telefonlarını üretebilmek adına kurulan 1200 metrekarelik fabrika ayda 1400 telefon üretme kapasitesindedir. İstanbul/Kurtköy`,
      projectTitle5:"Standlar",
      projectDesc5:`Partneri olduğumuz markaların talep ettiği standları tasarlamakla birlikte üretim ve kurulum aşamalarında gerekli desteği sağlamaktayız.`,
      miStands:'Mi Store Standları',
      tecnoStands:'Tecno Standları',
      partners:"İŞ ORTAKLARIMIZ",
      otherServices:"Diğer Hizmetler",
      whoWeAreHeader:"Biz Kimiz?",
      whoWeAreTitle:"Nasıl Çalışıyoruz?",
      whoWeAreDesc:`Mission, mimarlık ve iç mimarlık  alanında uzman kadrosunun gücüyle başta  İstanbul olmak üzere Tüm Türkiye'de tasarım ve mimarlık hizmetleri sunmaktadır.  Mümkün olan en güzel hizmeti sağlayabilmeyi amaçlayan ve bu doğrultuda müşterilerinin isteklerini göz önünde bulundurarak kaliteli mimarlık yapabilmeyi kendisine prensip edinmiştir.`,
      whoWeAreDesc2:`Mission disiplinli kadrosu ile hayatınızda farklılık yaratan tasarımlarıyla dünyada her an değişmekte olan mimari trendleri takip ederek farklı örnekleri yorumlayarak yeni bir bakış açısı getirmektedir. Bu yöntemlerle mimari tasarım anlayışına detay katarak mekandaki uyumu sağlayıp ve yaşam alanıyla kullanıcılarını bütünleştirmeyi hedeflemektedir.`,
      whoWeAreDesc3:`Mission, müşterilerinin mekansal ihtiyaçlarına ve zevklerine yönelik fonksiyonel ve dönüştürülebilen çözümler sunarak mimari ve iç mimari tasarım üretim ve uygulama alanında beklentilere cevap verebilen müşteri memnuniyeti odaklı bir firmadır. Firmamız mimari disiplinden kopmayan özgün tasarımlarını titiz bir uygulama ile birleştiren kadrosu ile mimarlık deneyiminin gücünü dinamik ve paylaşımcı ilkesinden alır.`,
      whoWeAreDesc4:`Mission, günümüzün tasarım ilkeleriyle birleşen yenilikçi modern mimari tasarımları takip eden ve tüm detaylarıyla projelerinde uygulamayı hedefleyen, daima fonksiyonel ve işlevsel tasarımlar sunmayı kendisine ilke edinmiştir.`,
      whoWeAreDesc5:`Ağırlıklı olarak çalışma ve yaşam alanları üzerinde uzmanlaşan Mission, iç mimarlık ve tasarım sektöründe geniş bir kapsamda faaliyet göstermekte, yenilikçi ve modern bakış açısıyla hayata geçirdiği projelerle, etki alanını sürekli olarak genişletmektedir..`,
      whoWeAreDesc6:`Şirketimiz proje uygulama süreçlerinde kullandığı malzeme ve teknolojiler sayesinde mevcut ya da doğabilecek sorunları doğru ve kalıcı çözümler oluşturarak ortadan kaldırmaktadır. Bu süreçte karşısına çıkan sorunlara yarattığı profesyonel birlikteliklerle yanıt vermektedir..`,
      whoWeAreDesc7:`Nitelikli ve ilham verici bir projenin, sürecin devamlılığı kadar işverenle etkileşimli bir diyalog sayesinde mümkün olduğuna inanan Mission, bu yaklaşımı kullanıcıya kaliteli ve mutluluk yüklü bir mekan deneyimi sağlamanın en önemli aracı olarak benimsemektedir..`,
      whoWeAreDesc8:`Kuruluşundan bu yana Tecno ve Xiaomi gibi dünyaca bilinen telefon üretici firmalara mimarlık ve danışmanlık hizmeti veren Mission, konsept tasarımdan proje yönetimine kadar, tasarlama eyleminin birçok safhasını gerçeğe dönüştürür ve kullanıcılarını hayal ettikleri mekanlarla buluşturur.`,
      address:"Adres",
      email:"Email",
      telephone:"Telefon",
      contactName:"Ad Soyad",
      subject:"Konu",
      message:"Mesaj",
      submit:"Gönder",
      namePlaceholder:"Adınızı soyadınızı giriniz",
      emailPlaceholder:"Email adresinizi giriniz",
      subjectPlaceholder:"Konunuzu giriniz",
      messagePlaceholder:"Mesajınızı giriniz",
      formSuccesMessage:"Mesajınız başarı ile gönderildi.",
      formDangerMessage:"Mesajınız gönderilemedi. Yeniden Deneyiniz!",
      servicesCardTitle:"İç Mimari Tasarım",
      servicesCardDesc:`İhtiyaçlarınızı detaylı analiz ettikten sonra, estetik ve kullanışlı iç mekan tasarımlarımız ile konforlu yaşam alanı sunuyoruz. Alanların verimli ve şık kullanımına dikkat eden Mission Danışmanlık, müşteri beklentilerini ve kurumsal profesyonelliğini birleştirerek göz alıcı tasarımlarını size sunuyor.`,
      servicesCardDesc_1:`Planlama, estetik ve teknik çözümleri ile sonuca ulaşmayı hedefleyen Mission iç mekanınızı kullanışlı, rahat ve şık hale getirir. `,
      servicesCardDesc_2:`Kişisel zevklerinizi dekor ederken, estetik unsurlarla tam donanım sağlamak Mission Danışmalığın verdiği kaliteli hizmetlerden biridir.`,
      servicesCardDesc_3:`Mission, alanında uzman mimar kadrosu ile yaşam alanınıza yeni bir kimlik katar.`,
      servicesCardTitle2:"Mimari Tasarım",
      servicesCardDesc2:`Mimari Tasarım, barınma ihtiyacı ile başlayan mekan yaratma sürecidir. Yapıların hem işlevsellik hem de estetik algısı gözetilerek, coğrafi, iklim koşulları ve kişisel ihtiyaçlara göre kurgulanması prensibi ile çalışır. Uygulanabilirliğinin yanı sıra, estetik, fonksiyonel ve ekonomik olması gibi birçok farklı çözümlerinin bir arada değerlendirildiği oldukça karmaşık süreçlerin birleşimi sonucu ortaya çıkar.`,
      servicesCardDesc2_1:`Bu uygulamaları hayata geçiren mimar, alanında uzman kişilerle çalışır. Ön proje denilen fikir projesi ise talep edilen her mimari çalışmanın somutlanması ile sunuma geçirilir.`,
      servicesCardDesc2_2:`Mission Danışmanlık tarafından somut hale getirilecek planların tekdüzelikten çıkmasındaki en büyük faktör bu uygulama projelerinin varlığıdır.`,
      servicesCardTitle3:`Danışmanlık`,
      servicesCardDesc3:`Mission Danışmanlık, donanımını ve sektör tecrübesini kullanarak müşteri beklentilerini hızlı, pratik, yaratıcı, akılcı, ekonomik ve teknik çözümleri ile karşılamak için hizmet verir.`,
      servicesCardDesc3_1:`Sürecin tümünde müşteri memnuniyeti odaklı çalışan Mission, gerekli tüm aşamaları yöneterek mimari danışmanlık görevini yerine getirir.`,
      servicesCardDesc3_2:`Müşterileri için, mekanın işlevsel ve nitelikli olabilmesine ilişkin sorunları tanımlar, araştırır, beklentileri karşılayacak ve aynı zamanda optimum çözümler üretir.`,
      servicesCardTitle4:`Özel Mobilya Tasarım`,
      servicesCardDesc4:`Alanında uzman mimarlarımızın dokunuşu eşliğinde, taleplerinize uygun olacak şekilde karar verilen renk, tasarım ve boyut özellikleri ile özel tasarım mobilya yapımı hizmeti veren Mission Danışmanlık, tam olarak beklenti ve ihtiyaçlarınıza yönelik çözümler sunmaktadır.`,
      servicesCardDesc4_1:`Bir mekan için gerekli olabilecek her tür mobilya, istenilen mekanın konseptine özgün olarak tasarlanır.`,
      servicesCardDesc4_2:`İstenilen renk ve dokuda üretimi yapılan özel tasarım mobilyalarınız dikkat çekici olmasının yanı sıra işlevselliği ile de büyük bir konfor alanı oluşturmanıza yardımcı olur.`,
      servicesCardDesc4_3:`Mission Danışmanlık, estetik zevkinizine uygun, konforlu, uyumlu ve ahenkli mobilyaları sizin için tasarlar`,
      contactUs:'İletişime Geçin',
    },
  },
};

export default translations;