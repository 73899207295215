import React from "react";
import { withTranslation } from "react-i18next";
import ForumMiContent from "../components/Projects/ForumMi/ForumMiContent";
import ForumMiSlider from "../components/Projects/ForumMi/ForumMiSlider";

const ForumMiScreen = ({ t }) => {
    return (
        <>
            <ForumMiContent/>
            <ForumMiSlider/>
        </>
    );
};

export default withTranslation()(ForumMiScreen);