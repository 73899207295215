/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const AnkaraNataSlider = ({t}) => {
    return (
        <section className="uk-section uk-padding-remove-top">
            <div className="uk-container">
                <div className="uk-child-width-1-3@m" uk-lightbox="animation: slide" data-uk-grid data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300;">
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/0.jpg`} data-caption={`${t("projectTitle")}-1`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/thumbnail/0.jpg`} alt={`${t("projectTitle")}-1`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/1.jpg`} data-caption={`${t("projectTitle")}-2`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/thumbnail/1.jpg`} alt={`${t("projectTitle")}-2`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/2.jpg`} data-caption={`${t("projectTitle")}-3`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/thumbnail/2.jpg`} alt={`${t("projectTitle")}-3`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/3.jpg`} data-caption={`${t("projectTitle")}-4`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/thumbnail/3.jpg`} alt={`${t("projectTitle")}-4`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/4.jpg`} data-caption={`${t("projectTitle")}-5`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/thumbnail/4.jpg`} alt={`${t("projectTitle")}-5`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/5.jpg`} data-caption={`${t("projectTitle")}-6`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/thumbnail/5.jpg`} alt={`${t("projectTitle")}-6`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/6.jpg`} data-caption={`${t("projectTitle")}-7`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/ankara-nata-vega/thumbnail/6.jpg`} alt={`${t("projectTitle")}-7`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default withTranslation()(AnkaraNataSlider);