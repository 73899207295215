import React from "react";
import { withTranslation } from "react-i18next";
//import AboutSection from "../components/Home/AboutSection";
import PartnersSection from "../components/Home/PartnersSection";
import ProjectsSection from "../components/Home/ProjectsSection";
import PromoSlider from "../components/Home/PromoSlider";
import ServicesSection from "../components/Home/ServicesSection";

const HomeScreen = ({ t }) => {
    return (
        <>
            <PromoSlider/>
            <ProjectsSection/>
            <section className="uk-section uk-padding-remove-top">
                <div className="uk-container">
                    <div className="uk-h2 text-white" data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">{t("partners")}</div>
                    <PartnersSection/>
                </div>
            </section>
            <ServicesSection/>
        </>
    );
};

export default withTranslation()(HomeScreen);