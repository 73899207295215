/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";

const AnkaraNataContent = ({t}) => {
    return (
        <section className="uk-section">
            <div className="uk-container">
                <div className="uk-h2">{t("projectTitle")}</div>
            </div>
        </section>
    );
};
export default withTranslation()(AnkaraNataContent);