/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withTranslation } from "react-i18next";

const MiContent = ({ t }) => {
    return (
        <section className="uk-section uk-section-small uk-padding-remove-top">
            <div className="uk-container">
                <div className="uk-h2" data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">{t("miStands")}</div>
                <div className="uk-child-width-1-2@s uk-child-width-1-3@m" uk-lightbox="animation: slide" data-uk-grid="masonry:true;" data-uk-scrollspy="target: > div; cls: uk-animation-fade; delay: 300;">
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/0.jpeg`} data-caption={`${t("miStands")}-1`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/0.jpeg`} alt={`${t("miStands")}-1`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/1.jpeg`} data-caption={`${t("miStands")}-2`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/1.jpeg`} alt={`${t("miStands")}-2`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/2.jpeg`} data-caption={`${t("miStands")}-3`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/2.jpeg`} alt={`${t("miStands")}-3`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/3.jpeg`} data-caption={`${t("miStands")}-4`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/3.jpeg`} alt={`${t("miStands")}-4`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                    <div>
                        <a className="uk-inline" href={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/4.jpeg`} data-caption={`${t("miStands")}-5`}>
                            <img src={`${process.env.PUBLIC_URL}/assets/projects/stands/mi/4.jpeg`} alt={`${t("miStands")}-5`} loading="lazy" className="uk-border-rounded" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(MiContent);