import React from "react";
import { withTranslation } from "react-i18next";
import ContactDescripton from '../components/Contact/ContactDescripton';
import ContactForm from '../components/Contact/ContactForm';
import ContactMap from '../components/Contact/ContactMap';

const ContactScreen = ({ t }) => {
    return (
        <>
            <ContactMap/>
            <ContactForm/>
            <ContactDescripton/>
        </>
    );
};

export default withTranslation()(ContactScreen);