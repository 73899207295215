/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ServicesSection = ({ t }) => {
    return (
        <section className="uk-section">
            <div className="uk-container">
                <div className="uk-flex-middle" data-uk-grid data-uk-scrollspy="cls: uk-animation-fade; delay: 300;">
                    <div className="uk-width-auto">
                        <div className="uk-h2 text-white uk-text-uppercase">{t("services")}</div>
                    </div>
                    <div className="uk-width-expand text-white uk-text-right">
                        <Link to="/services" className="uk-link-reset">{t("otherServices")}<span className="uk-margin-small-left" uk-icon="arrow-right"></span></Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation()(ServicesSection);