import React from "react";
import { withTranslation } from "react-i18next";
import AllProjects from "../components/Projects/AllProjects";
import ProjectsBanner from "../components/Projects/ProjectsBanner";

const ProjectsScreen = ({ t }) => {
    return (
        <>
            <ProjectsBanner/>
            <AllProjects/>
        </>
    );
};

export default withTranslation()(ProjectsScreen);